import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AboutImage, AboutInformation } from "../components/About";
import { Card } from "../models";
import { RitService } from "../services";

import "./RitAbout.scss";

const Rit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  
  const [rit, setRit] = useState<Card>();

  useEffect(() => {
    setRit(RitService.get().find((x) => x.id == Number(id)))
  }, [])

  if (!rit) return <></>;

  return (
    <React.Fragment>
      <div className="rit-about-container">
        <AboutImage src={rit.image} />
        <AboutInformation model={rit} />
      </div>
    </React.Fragment>
  );
};

export default Rit;
