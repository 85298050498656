import React, { useState, useEffect } from "react";
import {
  faNewspaper,
  faUtensils,
  faHome,
  faFeather,
} from "@fortawesome/free-solid-svg-icons";

import "./NavbarItemsContainer.scss";
import { NavbarItem } from "./NavbarItem";
import { useLocation } from "react-router-dom";

export const NavbarItemContainer = () => {
  const location = useLocation();

  console.log(location.pathname);

  return (
    <React.Fragment>
      <div className="navbar-items-container">
        <NavbarItem
          to="/"
          active={location.pathname}
          title="NOVOSTI"
          iconFont={faNewspaper}
        />
        <NavbarItem
          to="/accommodations"
          active={location.pathname}
          title="SMJEŠTAJ"
          iconFont={faHome}
        />
        <NavbarItem
          to="/restaurants"
          active={location.pathname}
          title="RESTORANI"
          iconFont={faUtensils}
        />
        <NavbarItem
          to="/rit"
          active={location.pathname}
          title="K. RIT"
          iconFont={faFeather}
        />
      </div>
    </React.Fragment>
  );
};
