import React, { useState, useEffect } from "react";
import { CardItemsContainer } from "../components/Card";
import { RitService } from "../services";

import "./Rit.scss";

export const Rit = () => {
  return (
    <React.Fragment>
      <div className="rit-container">
        <CardItemsContainer data={RitService.get()} />
      </div>
    </React.Fragment>
  );
};