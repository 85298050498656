import React, { useState, useEffect } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { CardItemsContainer } from "../components/Card";
import { withRestaurants, withSpinner } from "../recoil";
import { RestaurantService } from "../services";

export const Restaurants = () => {
  const [restaurants, setRestaurants] = useRecoilState(withRestaurants);
  const setSpinner = useSetRecoilState(withSpinner);

  useEffect(() => {
    if (restaurants && restaurants.length > 0)
      return;

    setSpinner(true);
    RestaurantService.get()
      .then((res) => {
        setRestaurants(res);
        setSpinner(false);
        console.log("restaurants", res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <React.Fragment>
      <div className="restaurants-item-container">
        <CardItemsContainer data={restaurants} />
      </div>
    </React.Fragment>
  );
};
