import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { AboutImage, AboutInformation } from "../components/About";
import { Card } from "../models";
import { withRestaurants } from "../recoil";

import "./Restaurant.scss";

export const Restaurant = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const restaurants = useRecoilValue(withRestaurants);
  const [restaurant, setRestaurant] = useState<Card>();

  useEffect(() => {
    setRestaurant(restaurants.find((x) => x.id == Number(id)));

    if (restaurants && restaurants.length == 0) navigate("/restaurants");
  }, []);

  if (!restaurant) return <></>;

  return (
    <React.Fragment>
      <div className="restaurant-container">
        <AboutImage src={restaurant.image} />
        <AboutInformation model={restaurant} />
      </div>
    </React.Fragment>
  );
};