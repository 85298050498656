import React, { useState, useEffect } from "react";
import { Card } from "../../models";
import {
  faLocationArrow,
  faLocationDot,
  faPhone,
  faEnvelope,
  faGlobe,
  faLink,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./AboutInformation.scss";
import DOMPurify from "dompurify";

type Props = {
  model: Card;
};

export const AboutInformation = ({ model }: Props) => {
  const googleMapsString: string = "https://www.google.com/maps/place/";

  return (
    <React.Fragment>
      <div className="about-information-container">
        <div className="about-name">
          <div className="name">
            <h2
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(model.title),
              }}
            ></h2>
          </div>

          <a href={googleMapsString + model.address}>
            <FontAwesomeIcon icon={faLocationArrow} />
          </a>
        </div>
        <div className="description">
          <p
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(model.description),
            }}
          ></p>
        </div>
        <div className="contact-links">
          {model.address && (
            <p>
              <FontAwesomeIcon icon={faLocationDot} />
              {model.address}
            </p>
          )}
          {model.phone && (
            <p>
              <FontAwesomeIcon icon={faPhone} />
              {model.phone}
            </p>
          )}
          {model.workingHours && (
            <p>
              <FontAwesomeIcon icon={faClock} />
              {model.workingHours}
            </p>
          )}
          {model.website && (
            <a href={model.website}>
              <FontAwesomeIcon icon={faGlobe} />
              {model.website}
            </a>
          )}
          {model.email && (
            <a href={`mailto: ${model.email}`}>
              <FontAwesomeIcon icon={faEnvelope} />
              {model.email}
            </a>
          )}
          {model.facebook && (
            <a href={model.facebook}>
              <FontAwesomeIcon icon={faLink} />
              Facebook
            </a>
          )}
          {model.instagram && (
            <a
              href={
                "https://www.instagram.com/" + model.instagram.replace("@", "")
              }
            >
              <FontAwesomeIcon icon={faLink} />
              Instagram
            </a>
          )}
          {model.youtube && (
            <a href={model.youtube}>
              <FontAwesomeIcon icon={faLink} />
              YouTube
            </a>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};
