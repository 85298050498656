import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";

import "./Navbar.scss";
import { Link } from "react-router-dom";
import { DownloadApp } from "../DownloadApp/DownloadApp";

export const Navbar = () => {
  return (
    <React.Fragment>
      <div className="navbar-container">
        <div className="main">
          <img
            className="logo"
            src="https://tzo-bilje.hr/wp-content/themes/tzbilje/dist/img/logo.svg"
            alt="logo"
          />
          <DownloadApp />
          {/* <div></div> */}
          {/* <Link to="/settings">
            <div className="settings">
              <FontAwesomeIcon icon={faGear} />
            </div>
          </Link> */}
        </div>
      </div>
    </React.Fragment>
  );
};
