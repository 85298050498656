import React, { useState, useEffect } from "react";

import "./Settings.scss"

export const Settings = () => {
  return (
    <React.Fragment>
      <div className="settings-container">
        <h2>Postavke</h2>
      </div>
    </React.Fragment>
  );
};
