import axios from 'axios';
import { Card } from '../models';

type response = {
    id: number,
    title: {
        rendered: string;
    },
    yoast_head_json: {
        og_image: {
            url: string;
        }[],
        og_description: string,
    },
    acf: {
        adresa?: string,
        telefon?: string
        radno_vrijeme?: string,
        web_adresa?: string,
        email_adresa: string,
        facebook: string,
        instagram: string,
        youtube: string
    }
}

export class AccommodationService {

    static baseUrl: string = 'https://tzo-bilje.hr/wp-json/wp/v2/smjestaj?per_page=100&_fields[]=id&_fields[]=title&_fields[]=yoast_head_json.og_image&_fields[]=yoast_head_json.og_description&_fields[]=acf'

    static async get() {
        const result = await axios.get<response[]>(AccommodationService.baseUrl)


        const response = result.data.map(x => ({
            id: x.id,
            title: x.title.rendered,
            image: x.yoast_head_json.og_image[0].url,
            description: x.yoast_head_json.og_description,
            address: x.acf.adresa,
            phone: x.acf.telefon,
            workingHours: x.acf.radno_vrijeme,
            website: x.acf.web_adresa,
            email: x.acf.email_adresa,
            facebook: x.acf.facebook,
            instagram: x.acf.instagram
        } as Card));

        return response;
    }
}