import React, { useState, useEffect } from "react";
import { Card } from "../../models";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useLocation } from "react-router-dom";
import TextTruncate from "react-text-truncate";

import "./CardItem.scss";
import DOMPurify from "dompurify";

type Props = {
  model: Card;
};

export const CardItem = ({ model }: Props) => {
  const location = useLocation();

  return (
    <React.Fragment>
      <Link to={`${location.pathname}/${model.id}`}>
        <div className="card-item-container">
          <img src={model.image} alt="no image awailable" />
          <div className="content">
            <h4
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(model.title),
              }}
            ></h4>
            <p className="adress">
              <FontAwesomeIcon icon={faLocationDot} /> {model.address}
            </p>
            <TextTruncate
              line={2}
              element="p"
              truncateText="..."
              text={ model.description.replace(/&(nbsp|amp|quot|lt|gt);/g, "")}
            />
          </div>
        </div>
      </Link>
    </React.Fragment>
  );
};
