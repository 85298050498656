import React, { useState, useEffect } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { CardItemsContainer } from "../components/Card";
import { withAccommodations, withSpinner } from "../recoil";
import { AccommodationService } from "../services";

export const Accommodations = () => {
  const [accommodations, setAccommodation] = useRecoilState(withAccommodations);
  const setSpinner = useSetRecoilState(withSpinner);

  useEffect(() => {
    if (accommodations && accommodations.length > 0)
      return;

    setSpinner(true);
    AccommodationService.get()
      .then((res) => {
        setAccommodation(res);
        setSpinner(false);
        console.log("accommodations", res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <React.Fragment>
      <div className="accommodations-item-container">
        <CardItemsContainer data={accommodations} />
      </div>
    </React.Fragment>
  );
};
