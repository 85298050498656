import { Card } from "../models";

export class RitService {
    public static get() {
        const result: Card[] = [
            {
                id: 1,
                title: "Prijemni centar Kopačevo",
                image: "https://pp-kopacki-rit.hr/wp-content/uploads/2019/07/201010090951h-e1562317291304.jpg",
                description: "Prijemni centar Kopačevo",
                address: "Mali Sakadaš 1, 31327 Kopačevo, Bilje",
                phone: "38531/445-445; +38531/752-320; +38531/752-322",
                workingHours: "Zimsko: 08.00 – 16.00 h Ljetno: 09.00 – 17.00 h",
                email: "prijemni.centar@pp-kopacki-rit.hr"
            },
            {
                id: 2,
                title: "Dvorac Tikveš",
                image: "https://pp-kopacki-rit.hr/wp-content/uploads/2019/05/Novi-dvorac_300dpi.jpg",
                description: "Kompleks dvoraca Tikveš obuhvaća objekte: Novi dvorac s Aneksom i Stari dvorac, a potječe iz 19 stoljeća. Izgradili su ga članovi Teschenske loze obitelji Habsburg. Tijekom povijesti dvorci su bili rezidencijalno lovačko središte poznato u europskim i svjetskim razmjerima, ali isključivo zatvorenog tipa za vladare i ugledne goste, koje je sada, nakon obnove i prenamjene, dostupno svim zainteresiranim posjetiteljima.",
                address: "Dvorac Tikveš, 31309, Lug",
                phone: "031/240 445",
                workingHours: "08 – 16 h",
                email: "prijemni.centar@pp-kopacki-rit.hr"
            },
            {
                id: 3,
                title: "Plovidba brodom",
                image: "https://pp-kopacki-rit.hr/wp-content/uploads/2019/07/DJI_0361-e1562751434245.jpg",
                description: "Na pristaništu na jezeru Veliki Sakadaš očekuje Vas brod kojim započinje uzbudljiva avantura kroz Posebni zoološki rezervat. Plovidba traje oko 1 h, a na brodu je već spreman stručni vodič s kojim ćete zajedno zaploviti kroz čudesni svijet poplavne doline Dunava. ",
                address: "Kopački Rit, Kopačevo",
                phone: "38531/445-445; +38531/752-320; +38531/752-322",
                workingHours: "Redovni termini za izlet brodom su u 11h, 13h, i 15h.",
                email: "prijemni.centar@pp-kopacki-rit.hr"
            }
        ];

        return result;
    }
}