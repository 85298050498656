import React, { useState, useEffect } from "react";
import { PropagateLoader } from "react-spinners";
import { useRecoilValue } from "recoil";
import { withSpinner } from "../../recoil/Spinner/atom";

import "./Loading.scss";

export const Loading = () => {
  const spinner = useRecoilValue(withSpinner);

  if (!spinner)
    return <></>

  return (
    <React.Fragment>
      <div className="loading-container">
        <PropagateLoader
          color={"#333333"}
          loading={spinner}
          // size={50}
          className="spinner"
          aria-label="Loading Spinner"
        />
      </div>
    </React.Fragment>
  );
};
