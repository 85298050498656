import React, { useState, useEffect } from "react";
import DOMPurify from "dompurify";
import TextTruncate from "react-text-truncate";
import { News } from "../../models";

import "./NewsCard.scss";

type Props = {
  model: News;
};

export function convertUnicode(input: string) {
  return input.replace(/\\+u([0-9a-fA-F]{4})/g, (a, b) =>
    String.fromCharCode(parseInt(b, 16))
  );
}

const NewsCard = ({ model }: Props) => {
  return (
    <React.Fragment>
      <a href={model.link} target="_blank">
        <div className="news-card-container active">
          <img src={model.image} alt="no image" />
          <h2
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(model.title),
            }}
          ></h2>
          <TextTruncate
            line={4}
            element="p"
            truncateText="..."
            text={model.description}
          />
        </div>
      </a>
    </React.Fragment>
  );
};

export default NewsCard;
