import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { AboutImage, AboutInformation } from "../components/About";
import { Card } from "../models";
import { withAccommodations } from "../recoil";

import "./Accommodation.scss";

export const Accommodation = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const accommodations = useRecoilValue(withAccommodations);
  const [accommodation, setAccommodation] = useState<Card>();

  useEffect(() => {
    setAccommodation(accommodations.find((x) => x.id == Number(id)));

    if (accommodations && accommodations.length == 0) navigate("/accommodations");
  }, []);

  if (!accommodation) return <></>;

  return (
    <React.Fragment>
      <div className="accommodation-container">
        <AboutImage src={accommodation.image} />
        <AboutInformation model={accommodation} />
      </div>
    </React.Fragment>
  );
};