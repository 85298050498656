import React, { useState, useEffect } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { NewsCardContainer } from "../components/News";
import { withNews, withSpinner } from "../recoil";
import { NewsService } from "../services";

import "./Home.scss";

export const Home = () => {
  const [news, setNews] = useRecoilState(withNews);
  const setSpinner = useSetRecoilState(withSpinner);

  useEffect(() => {
    if (news && news.length > 0)
      return;

    setSpinner(true);
    NewsService.get()
      .then((res) => {
        setNews(res);
        setSpinner(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <React.Fragment>
      <div className="home-container">
        <NewsCardContainer data={news} />
      </div>
    </React.Fragment>
  );
};
