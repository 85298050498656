import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { usePWAInstall } from "react-use-pwa-install";
import { useDownloadApp } from "./DownloadApp.hooks";
import "./DownloadApp.scss";

export const DownloadApp = () => {
  const installApp = useDownloadApp();
  const isInstalled = !window.matchMedia("(display-mode: standalone)").matches;

  return (
    <React.Fragment>
      <div className="download-app-container">
        {isInstalled && (
          <button onClick={async () => await installApp()}>
            <FontAwesomeIcon icon={faDownload} />
          </button>
        )}
      </div>
    </React.Fragment>
  );
};
