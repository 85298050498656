import React, { useEffect } from "react";
import { RecoilRoot, useRecoilValue } from "recoil";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Navbar, NavbarItem, NavbarItemContainer } from "./components/Navbar";
import { Loading } from "./components/Common";
import { isBrowser } from "react-device-detect";

// screens
import { Restaurant, Restaurants, Home, Accommodations, Accommodation, Settings, Rit } from "./screens";

import "./App.scss";
import RitAbout from "./screens/RitAbout";

const App = () => {
  useEffect(() => {
    if (isBrowser) {
      window.location.replace("https://tzo-bilje.hr/");
      return;
    }
  }, [])

  return (
    <React.Fragment>
      <RecoilRoot>
        <BrowserRouter>
          <Loading />
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/restaurants" element={<Restaurants />} />
            <Route path="/restaurants/:id" element={<Restaurant />} />
            <Route path="/accommodations" element={<Accommodations />} />
            <Route path="/accommodations/:id" element={<Accommodation />} />
            <Route path="/rit" element={<Rit />} />
            <Route path="/rit/:id" element={<RitAbout />} />
            <Route path="/settings" element={<Settings />} />
          </Routes>
          <NavbarItemContainer />
        </BrowserRouter>
      </RecoilRoot>
    </React.Fragment>
  );
};

export default App;
