import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

import "./NavbarItem.scss";
import { Link } from "react-router-dom";

type Props = {
  active?: string;
  title: string;
  iconFont: IconProp;
  to: string;
};

export const NavbarItem = ({ title, iconFont, active, to }: Props) => {
  return (
    <React.Fragment>
      <Link to={to}>
        <div className={`navbar-item-container ${active === to && "active-nav-item"}`}>
          <FontAwesomeIcon icon={iconFont} />
          <p>{title}</p>
        </div>
      </Link>
    </React.Fragment>
  );
};
