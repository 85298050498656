import React, { useState, useEffect } from "react";
import { News } from "../../models";
import NewsCard from "./NewsCard";

import "./NewsCardContaienr.scss";

type Props = {
  data: News[]
}

export const NewsCardContainer = ({data}: Props) => {
  return (
    <React.Fragment>
      <div className="news-cards-container">
        {data.map((x, i) => (<NewsCard key={i} model={x} />))}
      </div>
    </React.Fragment>
  );
};
