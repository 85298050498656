import axios from 'axios';
import { News } from '../models';

type response = {
    guid: {
        rendered: string;
    },
    title: {
        rendered: string;
    },
    yoast_head_json: {
        og_image: {
            url: string;
        }[],
        og_description: string,
    }
}

export class NewsService {

    private static baseUrl: string = 'https://tzo-bilje.hr/wp-json/wp/v2/posts?per_page=100&_fields[]=title&_fields[]=yoast_head_json.og_image&_fields[]=yoast_head_json.og_description&_fields[]=guid'

    static async get(): Promise<News[]> {
        const result = await axios.get<response[]>(NewsService.baseUrl)
        console.log(result);


        const mapped = result.data.filter(x => x.yoast_head_json.og_image)
            .map(x => ({
                link: x.guid.rendered,
                title: x.title.rendered,
                image: x.yoast_head_json.og_image[0].url,
                description: x.yoast_head_json.og_description,
            } as News));

        const response = mapped ? mapped : [] as News[]

        return response;
    }
}