import React, { useState, useEffect } from "react";
import { Card } from "../../models";
import { CardItem } from "./CardItem";

import "./CardItemsContainer.scss";

type Props = {
  data?: Card[]
};

export const CardItemsContainer = ({ data }: Props) => {
  return (
    <React.Fragment>
      <div className="card-items-container">
        {data?.map((x, i) => <CardItem key={i} model={x} />)}
      </div>
    </React.Fragment>
  );
};
