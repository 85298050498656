import { useEffect, useState } from "react";
import { usePWAInstall } from "react-use-pwa-install"

export const useDownloadApp = () => {
    const pwaInstall = usePWAInstall();
    const [isInstalled, setIsInstalled] = useState(false);

    const installApp = async () => {

        if (!pwaInstall) {
            return;
        }

        const install = pwaInstall();

        if (!install) {
            return;
        }

        await install;
    }

    return installApp;
}