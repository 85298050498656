import React, { useState, useEffect } from "react";

import "./AboutImage.scss";

type Props = {
  src: string;
};

export const AboutImage = ({ src }: Props) => {
  return (
    <React.Fragment>
      <div className="about-image-container">
        <img src={src} alt="no image.." />
      </div>
    </React.Fragment>
  );
};
